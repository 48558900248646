
class Utils {

    static addClass = (element, class_) => {
        if (
            element === undefined ||
            element === null ||
            class_ === undefined ||
            class_ === null ||
            element.classList === undefined ||
            element.classList.contains(class_)
        ) return;

        element.classList.add(class_);
    };

    static removeClass = (element, class_) => {
        if (
            element === undefined ||
            element === null ||
            class_ === undefined ||
            class_ === null ||
            element.classList === undefined ||
            !element.classList.contains(class_)
        ) return;

        element.classList.remove(class_);
    };
}

export default Utils;