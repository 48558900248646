import "./HeaderContent.css";

function HeaderContent() {
    return (
        <div id="HeaderContent">
            <div className="HeaderContentContainer">
                <p className="text-homepage-my-name-is font-bold">
                    Hello, my name is
                </p>

                <p className="text-homepage-name font-medium">
                    Samuele Perticarari.
                </p>

                <p className="text-homepage-what-i-do font-book">
                    I create things with code.
                </p>

                <p className="text-homepage-what-i-do-description font-medium">
                    I'm a new grad in Computer and Automation Engineering @
                    Marche Polytechnic University. Very enthusiastic about new
                    technologies and curious to explore.
                </p>
            </div>
        </div>
    );
}

export default HeaderContent;
