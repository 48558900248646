import "./Project.css";
import Badge from "../../Components/Badge/Badge";
import React from "react";

class Project extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            container_class:
                props.index % 2 === 0
                    ? "project-item image-right"
                    : "project-item image-left",
        };
    }

    render() {
        let button_link;

        if (this.state.data.link.substring(0, 19) === "https://github.com/") {
            button_link = (
                <a
                    className="btn-light"
                    target={"_blank"}
                    rel="noreferrer"
                    href={this.state.data.link}
                >
                    Open repo
                </a>
            );
        } else {
            button_link = (
                <a
                    className="btn-light"
                    target={"_blank"}
                    rel="noreferrer"
                    href={this.state.data.link}
                >
                    Open link
                </a>
            );
        }

        return (
            <li key={this.state.data.title} className={this.state.container_class}>
                <div className="project-image-container">
                    <a
                        href={this.state.data.link}
                        rel="noreferrer"
                        target={"_blank"}
                    >
                        <img
                            src={this.state.data.image}
                            className="project-image"
                            alt=""
                        />
                    </a>
                </div>
                <div className="project-text-container">
                    <div className="project-title-container">
                        {this.state.data.title}
                    </div>

                    <div className="project-description-container">
                        {this.state.data.description}
                    </div>

                    <div className="project-tags-container">
                        {this.state.data.tags.map((tag, index) => (
                            <Badge key={index} text={tag} />
                        ))}
                    </div>

                    <div className="project-links-container">{button_link}</div>
                </div>
            </li>
        );
    }
}

export default Project;
