import './Footer.css'

function Footer() {

  return (
    <div id="footer">
      
    </div>
  )
}


export default Footer;