import "./About.css";
import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

class About extends React.Component {
    render() {
        return (
            <div id="about" className="section">
                <div className="section-title-container">
                    <h1 className="section-title">Let's connect!</h1>
                </div>

                <div className="section-container">
                    <div className="get-in-touch">
                        <p>
                            I'm excited to collaborate with others on innovative
                            projects, and I'm always looking to expand my
                            network and <b>meet new people</b>.
                        </p>
                        <p>
                            If you have a new project <b>idea</b> that you'd
                            like to discuss, <b>feel free to reach out</b> and
                            start a conversation.
                        </p>

                        <div className="contact-box-container">
                            <a
                                href="https://github.com/SamuPert"
                                target={"_blank"}
                                className="contact-box"
                            >
                                <GitHubIcon />
                                <span>Github</span>
                                <span>SamuPert</span>
                            </a>

                            <a
                                href="https://www.linkedin.com/in/samuele-perticarari/"
                                target={"_blank"}
                                className="contact-box"
                            >
                                <LinkedInIcon />
                                <span>LinkedIn</span>
                                <span>Samuele Perticarari</span>
                            </a>

                            <a
                                href="mailto:samuele.perticarari@gmail.com"
                                target={"_blank"}
                                className="contact-box"
                            >
                                <AlternateEmailIcon />
                                <span>E-mail</span>
                                <span>samuele.perticarari@gmail.com</span>
                            </a>
                        </div>
                        <p>
                            Currently, I'm looking for an open-source project to
                            contribute to, as I believe in the power of
                            open-source and its potential to drive innovation
                            and positive change.
                        </p>
                        <p>
                            Don't hesitate to <b>connect with me</b> on LinkedIn
                            or via mail if you'd like to chat or collaborate on
                            a project together!
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
