import './Badge.css'
import React from 'react';

class Badge extends React.Component {

    constructor(props) {
    super(props);
        this.state = { text: props.text };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="badge">
                {this.state.text}
            </div>
        )
    }
}


export default Badge;