import "./CaseStudies.css";
import Project from "./Project/Project";

function CaseStudies() {
    let projects = [
        {
            title: "Distributed CTF Tool",
            description: `I am excited to share my bachelor's degree thesis, which focuses on designing a distributed system for scheduling and executing exploits and flag submissions in "Attack and Defense" CTF competitions. In this era of increasing dependence on digital technologies, cybersecurity has become more important than ever. The thesis explores the challenges of CTF competitions and presents an approach for designing a distributed system that can address these challenges. The impact of the design choices on system performance and availability is also evaluated.`,
            image: "/assets/images/hld-distributed-ctf-tool.png",
            link: "https://github.com/SamuPert/distributed-ctf-tool",
            tags: [
                "docker",
                "kubernetes",
                "cassandra",
                "serverless",
                "tikv",
                "event-driven-architecture",
                "cri-o",
                "knative",
                "rook-ceph",
                "rabbitmq",
            ],
        },
        {
            title: "Intro to reverse engineering and software exploitation",
            description:
                "This is the training material that i used to train the participants of the CyberChallenge.it 2020 contest of the Marche Polytechnic University on reverse engineering and software exploitation.",
            image: "/assets/images/intro-to-reverse-engineering-and-software-exploitation.jpg",
            link: "https://github.com/SamuPert/intro-to-reverse-engineering-and-software-exploitation",
            tags: [
                "reverse-engineering",
                "stack-overflow",
                "buffer-overflow",
                "training-material",
                "software-exploitation",
            ],
        },
        {
            title: "Euro.net wifi",
            description:
                "Full redesign of Euro.net website, based on Wordpress CMS. Created a custom wordpress theme, based on business requirements.",
            image: "/assets/images/euronetwifi.it.png",
            link: "https://www.euronetwifi.it/",
            tags: ["PHP", "Wordpress", "CSS", "Javascript"],
        },
        {
            title: "Music Shop",
            description:
                'Multi-user web application for managing and displaying music store items. Project developed during "Web development" course.',
            image: "/assets/images/music_shop.png",
            link: "https://github.com/SamuPert/music-shop-app",
            tags: [
                "PHP",
                "Laravel",
                "SCSS",
                "Javascript",
                "Laravel MIX",
                "Bootstrap",
            ],
        },
        {
            title: "InsHackati's Butchery",
            description:
                "CTF Attack And Defense tool for automating attacks and sending flags to local competitive server.",
            image: "/assets/images/attack_and_defense.jpg",
            link: "https://github.com/SamuPert/InsHackati-s-Butchery",
            tags: ["python", "sqlite"],
        },
        {
            title: "House Manager",
            description:
                'Awesome Android application for managing the expenses of a student at home and organizing sports tournaments. Project developed during "Mobile Programming" course.',
            image: "/assets/images/house_manager.png",
            link: "https://github.com/SamuPert/house-manager-app",
            tags: ["Java", "Google Cloud Functions", "Android", "C#"],
        },
    ];

    /*
    
  */

    return (
        <div id="projects" className="section">
            <div className="section-title-container">
                <h1 className="section-title">Projects</h1>
            </div>

            <div className="section-container">
                {projects.map((project, index) => (
                    <Project key={index} data={project} index={index} />
                ))}
            </div>
        </div>
    );
}

export default CaseStudies;
