import React from 'react';
import 'particles.js/particles'
import './CoolBackground.css'

class CoolBackground extends React.Component {



  componentDidMount() {
    window.particlesJS.load('particles-background', 'assets/particle_config.json');
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div id="particles-background"></div>
    );
  }

}

/* particlesJS.load(@dom-id, @path-json, @callback (optional)); */


export default CoolBackground;