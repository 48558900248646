import './Header.css'
import CoolBackground from './CoolBackground/CoolBackground'
import HeaderContent from './HeaderContent/HeaderContent'

function Header() {
  return (
    <div className="HeaderContainer" id="home">

        <CoolBackground />
    
        <HeaderContent />
        

    </div>
  )

  /*


  */
}


export default Header;