import React from 'react';
// import './linkedin-profile.js'
import './animations.css';
import './fonts.css';
import './App.css';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import CaseStudies from './CaseStudies/CaseStudies';
import About from './About/About';
import FlexibleNavbar from './FlexibleNavbar/FlexibleNavbar';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-M6CKN97',
}

TagManager.initialize(tagManagerArgs)

class App extends React.Component {

  render() {

    return (
      <div className="App">
        <Header />
        <FlexibleNavbar />

        <div className="main-content-container">

          <div className="main-content">

            <CaseStudies />

            <About />


          </div>

        </div>

        <Footer />

      </div>
    );
  }
}

export default App;